import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { SetupIntent, StripeCardNumberElement, StripeError } from '@stripe/stripe-js';

import { Grid } from '@breathelife/mui';
import { Text } from '@breathelife/ui-components';
import { AnyReturn } from '../../shared';
import { useStripeContext } from '../StripeContext';
import { StripeCardChangeEvent, StripeFormData, StripeFormErrors, StripeFormKey } from '../types';
import { localizedFormLabels } from './localization';
import { isFormValidForSubmission, validateFormInput } from './validation';
import { CardCvcInput, CardExpiryInput, CardNumberInput, TextInput } from './styles';

export type StripeCreditCardFormProps = {
  clientSecret: string;
  disabled?: boolean;
  showCardIcon?: boolean;
};

export type StripeFormRefHandle = {
  onSubmit: (opts: {
    onSubmitFailure?: (error: Error | StripeError) => AnyReturn;
    onSubmitSuccess?: (result: SetupIntent) => AnyReturn;
    onSubmitValidationFailure?: () => AnyReturn;
  }) => Promise<void>;
};

export const StripeCreditCardForm = forwardRef(function Form(
  props: StripeCreditCardFormProps,
  ref: ForwardedRef<StripeFormRefHandle>,
): ReactElement | null {
  const { clientSecret, disabled = false, showCardIcon = true } = props;
  const elements = useElements();
  const stripe = useStripe();
  const { language } = useStripeContext();

  const [formData, setFormData] = useState<StripeFormData>({
    [StripeFormKey.cardCvc]: { isComplete: false, isEmpty: true },
    [StripeFormKey.cardExpiry]: { isComplete: false, isEmpty: true },
    [StripeFormKey.cardNumber]: { isComplete: false, isEmpty: true },
    [StripeFormKey.postalCode]: '',
  });
  const [formErrors, setFormErrors] = useState<StripeFormErrors>({});
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const lockInputs = disabled || isLocked;
  const renderForm = clientSecret && stripe && elements;

  const formLabels = useMemo(() => localizedFormLabels[language], [language]);

  const confirmCardSetup = useCallback(async () => {
    return await stripe?.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements?.getElement(CardNumberElement) as StripeCardNumberElement,
        billing_details: {
          address: {
            postal_code: formData.postalCode,
          },
        },
      },
    });
  }, [clientSecret, elements, formData.postalCode, stripe]);

  const onStripeCardChange = (event: StripeCardChangeEvent, key: StripeFormKey): void => {
    const value = { isComplete: event.complete, isEmpty: event.empty };
    const updatedData: StripeFormData = { ...formData, [key]: value };
    const inputError = validateFormInput(value, key);

    setFormData(updatedData);
    setFormErrors({ ...formErrors, [key]: inputError });
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>, key: StripeFormKey): void => {
    const value = event?.target?.value ?? '';
    const updatedData: StripeFormData = { ...formData, [key]: value };
    const inputError = validateFormInput(value, key);

    setFormData(updatedData);
    setFormErrors({ ...formErrors, [key]: inputError });
  };

  useImperativeHandle(
    ref,
    () => ({
      onSubmit: async ({ onSubmitFailure, onSubmitSuccess, onSubmitValidationFailure }) => {
        try {
          setIsLocked(true);

          if (!isFormValidForSubmission(formData)) {
            setIsLocked(false);
            return onSubmitValidationFailure?.();
          }

          const confirmation = await confirmCardSetup();

          if (confirmation?.setupIntent) {
            setIsLocked(false);
            return onSubmitSuccess?.(confirmation.setupIntent);
          }

          if (confirmation?.error) {
            setIsLocked(false);
            return onSubmitFailure?.(confirmation.error);
          }

          setIsLocked(false);
        } catch (err: any) {
          setIsLocked(false);
          return onSubmitFailure?.(err);
        }
      },
    }),
    [confirmCardSetup, formData],
  );

  // The form has to render conditionally
  // If stripe or elements are undefined, the form is unresponsive
  return renderForm ? (
    <Grid container spacing={2} data-testid='stripe-credit-card-form'>
      <Grid item container direction='column' spacing={1}>
        <Grid item>
          <Text variant='small-copy'>{formLabels.cardNumber}</Text>
        </Grid>
        <Grid item>
          <CardNumberInput
            options={{ disabled: lockInputs, showIcon: showCardIcon }}
            onChange={(event) => onStripeCardChange(event, StripeFormKey.cardNumber)}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item container direction='column' xs spacing={1}>
          <Grid item>
            <Text variant='small-copy'>{formLabels.cardExpiry}</Text>
          </Grid>
          <Grid item>
            <CardExpiryInput
              options={{ disabled: lockInputs }}
              onChange={(event) => onStripeCardChange(event, StripeFormKey.cardExpiry)}
            />
          </Grid>
        </Grid>
        <Grid item container direction='column' xs spacing={1}>
          <Grid item>
            <Text variant='small-copy'>{formLabels.cardCvc}</Text>
          </Grid>
          <Grid item>
            <CardCvcInput
              options={{ disabled: lockInputs, placeholder: '123' }}
              onChange={(event) => onStripeCardChange(event, StripeFormKey.cardCvc)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction='column' spacing={1}>
        <Grid item>
          <Text variant='small-copy'>{formLabels.postalCode}</Text>
        </Grid>
        <Grid item>
          <TextInput
            autoComplete='billing postal-code'
            disabled={lockInputs}
            error={!!formErrors?.postalCode?.message}
            fullWidth
            name='postalCode'
            onChange={(event: ChangeEvent<HTMLInputElement>) => onInputChange(event, StripeFormKey.postalCode)}
            variant='outlined'
            value={formData.postalCode}
          />
        </Grid>
      </Grid>
    </Grid>
  ) : null;
});
